import "./app/logconfig";
import React from "react";
import TagManager from "react-gtm-module";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { gapi } from "gapi-script";
import { Navbar } from "./components";
import {
	Blog,
	Cookies,
	Dashboard,
	Home,
	Howto,
	Login,
	Logout,
	Open,
	Pricing,
	Privacy,
	Resources,
	Schedule,
	Settings,
	Tabcase,
	TabinExtension,
	Welcome1,
	Welcome2,
	Welcome3,
} from "./pages";
import TabinExplorer from "./pages/TabinExplorer";
import styles from "./style";
import ReactGA from "react-ga4";

import { Mixpanel } from "./app/mixpanel-instance";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
console.log(
	"google analytics ",
	process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
);
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENTID;
// const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
// const DISCOVERY_DOC =
// 	"https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest";

const TagManagerArgs = {
	gtmId: "GTM-KJQDP7LN",
};

TagManager.initialize(TagManagerArgs);

function App() {
	// useEffect(() => {
	// 	function start() {
	// 		gapi.auth2.init({
	// 			clientId: CLIENT_ID,
	// 			scope: "profile",
	// 		});
	// 	}
	// 	gapi.load("auth2", start);
	// });

	return (
		<>
			<Router>
				<div className='w-full overflow'>
					<div className={`${styles.paddingX} `}>
						<div className={`${styles.boxWidth}`}>
							<Navbar />
						</div>
					</div>{" "}
				</div>

				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/blog' element={<Blog />}></Route>
					<Route path='/cookies' element={<Cookies />} />
					<Route path='/dashboard' element={<Dashboard />} />
					<Route path='/explorer' element={<TabinExplorer />} />
					<Route path='/howto' element={<Howto />} />
					<Route path='/login' element={<Login />} />
					<Route path='/logout' element={<Logout />} />
					<Route path='/mytabins' element={<Tabcase />} />
					<Route path='/open' element={<Open />} />
					<Route path='/pricing' element={<Pricing />} />
					<Route path='/privacy' element={<Privacy />} />
					<Route path='/resources' element={<Resources />} />
					<Route path='/schedule' element={<Schedule />} />
					<Route path='/settings' element={<Settings />} />
					<Route path='/welcome-1' element={<Welcome1 />} />
					<Route path='/welcome-2' element={<Welcome2 />} />
					<Route path='/welcome-3' element={<Welcome3 />} />
					<Route path='/extensions' element={<TabinExtension />} />
				</Routes>
			</Router>
		</>
	);
}

export default App;
